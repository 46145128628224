import request from '@/utils/request'

function getTraceType(val) {
    const List = {
        10: '打开链接',
        11: '停留页面超过5秒',
        12: '滑动页面',

        21: '输入信息-手机号',

        31: '点击获取验证码',
        32: '点击领取赠险',
        33: '点击加入保障-未确认',
        34: '点击加入保障-已确认',
        35: '点击升级保障-未确认',
        36: '点击升级保障-已确认',

        37: '',
        38: '',

        41: '获取赠险成功',
        42: '获取赠险失败',


        51: '支付成功',



        91: '问题导出',
    }
    return List[val] || ''
}

//按照后台要求 将对象转成json字符串
function getJSONObj(obj) {
    if (typeof obj === 'object' && obj !== null) {
        return JSON.stringify(obj).replace(/"/g, "'")
    } else {
        return ''
    }
}

function getBaseInfo(that) {
    let traceId = sessionStorage.getItem('trace-log-uuid')
    return {traceId, ...that.$route.query, ...that.$route.params}
}

function add(parameter) {
    return request({
        url: '/open/insTraceLog/add',
        method: 'post',
        data: parameter
    })
}

function edit(parameter) {
    return request({
        url: '/open/insTraceLog/edit',
        method: 'post',
        data: parameter
    })
}

//埋点方法类
export default class TraceLog {
    static pointAdd = (dynamicInfo, el) => {
        let { traceType, traceDesc, privateData, that } = dynamicInfo
        let baseInfo = getBaseInfo(that)
        const params = {
            traceType: traceType,   //日志类型
            traceDesc,              //事件名称
            privateData: getJSONObj(privateData),
            ...baseInfo
        }
        return new Promise((resolve, reject) => {
            add(params).then((res) => {}).catch((error) => {reject(error)})
        })
    }
    /**
     * 更新埋点通用函数
     * @param id 此次需要更新的埋点id (必填)
     * @param type 功能类型 eg('按钮点击') (必填)
     * @param updateData 更新的私有参数 (必填)
     * @returns {Promise} 返回更新埋点的状态
     */
    static pointUpdate = (id, type, updateData) => {
        const params = {
            type: type,
            updateData: getJSONObj({
                id,
                ...updateData,
            }),
        }
        return new Promise((resolve, reject) => {
            edit(params)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                    } else {
                        reject(res)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    /**
     * 按钮点击更新埋点辅助函数
     * @param event 点击事件的 event 对象 （必填）
     * @param operateResultBool 点击事件的操作结果 true || false （必填）
     * @returns {updatePromise} 返回更新埋点的状态
     */
    static pointUpdateHelper = async (event, operateResultBool) => {
        if (!event || operateResultBool === undefined) {
            return Promise.reject(new Error('更新埋点参数缺失'))
        }
        const updateData = {
            operateResult: operateResultBool ? '操作成功' : '操作失败',
        }
        console.log(event?.target.closest('.trace-log-element'))
        const targetBtn = event?.target.closest('.trace-log-element')
        //返回更新埋点的状态
        let updatePromise
        if (targetBtn) {
            const id = targetBtn.dataset.id
            const type = targetBtn.dataset.type
            updatePromise = await this.pointUpdate(id, type, updateData)
        } else {
            updatePromise = Promise.reject(new Error('获取不到更新埋点id'))
        }
        return updatePromise
    }
}

// 定义防抖函数
export function debounce(fn, wait) {
    // 自由变量，debounce执行完成被释放，time也不会被释放
    let time;
    // 返回一个闭包
    return function () {
        // 清除上一次的定时器
        if (time) {
            clearTimeout(time);
        };
        // wait时间后执行
        time = setTimeout(fn, wait);
    }
}

