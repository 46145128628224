import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/:linkId',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ 'views/home.vue'),
        meta: {
            title: '加载中...无反应，请刷新',
            ico: 'loading'
        }
    },
    {
        path: '/review',
        name: 'review',
        component: () => import(/* webpackChunkName: "home" */ '../views/rrwebPlayer.vue'),
        meta: {
            title: '操作回放',
            ico: 'review'
        }
    },

    //赠险
    {
        path: '/ins/give/11',
        name: 'give1',
        component: () => import('@/views/ins/give/#1/index1.vue'),
        meta: {
            title: '保险福利礼包',
            ico: 'fl',
            keepAlive: false // 禁用缓存
        }
    },

    //钩子
    {
        path: '/ins/base/1',
        name: 'base1',
        component: () => import('@/views/ins/base/index1.vue'),
        meta: {
            title: '保险福利礼包',
            ico: 'fl'
        }
    },
    {
        path: '/ins/main/1',
        name: 'main1',
        component: () => import('@/views/ins/base/main1.vue'),
        meta: {
            title: '保险福利礼包',
            ico: 'fl'
        }
    },
    {
        path: '/ins/main/2',
        name: 'main2',
        component: () => import('@/views/ins/base/main2.vue'),
        meta: {
            title: '保险福利礼包',
            ico: 'fl'
        }
    },

    //升级



    //
    {
        path: '/ins/waiting',
        name: 'waiting',
        component: () => import('views/ins/waiting.vue'),
        meta: {
            title: '保险福利礼包',
            ico: 'fl'
        }
    },
    {
        path: '/ins/success',
        name: 'success',
        component: () => import('views/ins/upgrade/result1.vue'),
        meta: {
            title: '保险福利礼包',
            ico: 'fl'
        }
    },


    {
        path: '/medical/index',
        name: 'medical-index',
        component: () => import('views/medical/index.vue'),
        meta: {
            title: '保障详情',
            ico: 'fl'
        }
    },
    {
        path: '/medical/pay-result',
        name: 'medical-pay-result',
        component: () => import('views/medical/pay-result/pay-result.vue'),
        meta: {
            title: '支付结果',
            ico: 'fl'
        }
    },

]

const router = createRouter({
    routes,
    history: createWebHistory('/'),
    scrollBehavior() {
        return {
            top: 0
        }
    }
})

// 定义页面标题
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.meta.ico) {
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
        link.type = 'image/x-icon'
        link.href = require('@/assets/images/icon/' + to.meta.ico + '.png')
        document.getElementsByTagName('head')[0].appendChild(link)
    }
    next()
})

export default router
