import {createApp} from 'vue'
import App from './App.vue'
import {nanoid} from "nanoid"
import initApp from './init'
import store from './store'
import router from './router'

let app = createApp(App)
initApp(app)
app.use(store).use(router).mount('#app')

router.afterEach((to, from, next) => {
    //优先取url上面携带的埋点id
    const uuid = to.query.logId ? to.query.logId : nanoid()
    if (!to.query.logId) {
        to.query.logId = uuid
        console.log('会话id：' + uuid)
    }
    sessionStorage.setItem('trace-log-uuid', uuid)
})

