import axios from 'axios'
import { getToken } from './tokenStore'
const service = axios.create({
  baseURL: '/api',
  timeout: 60000*5
})

// request拦截器 request interceptor
service.interceptors.request.use(
  config => {
    let token = sessionStorage.getItem('trace-log-uuid')
    if (token) {
      config.headers['traceId'] =  token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.status && response.status !== 200) {
      return Promise.reject(res)
    } else {
      return Promise.resolve(res)
    }
  },
  error => {
    return Promise.reject(error)
  }
)
export default service
